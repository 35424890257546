@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind forms;

.pbd-main-nav-active-link {
    @apply bg-gray-300 text-gray-700;
}

.pbd-blur {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    cursor: wait;
}

.pbd-blur-animation-background {
    border: 1px solid rgba(0,0,0,0.1);
}

.pbd-blur-animation {
    animation-duration: 3s;
    animation-name: pbdbluranimation;
    animation-iteration-count: infinite;
    width: 100%;
    height: 30px;
    border: 1px solid rgba(0,0,0,0.25);
    position: absolute;
    left: 0;
    background-size: cover;
    border-radius: 5px;
    background-repeat: no-repeat;
    transition: all 1s ease-out;
}

.pbd-blur-animation-finished {
    animation-name: none;
    height: 100%;
    top: 0;
}

@keyframes pbdbluranimation {
    0% {
        background-position-y: 0;
        top: 0;
    }

    50% {
        background-position-y: calc(100% - 30px);
        top: calc(100% - 30px);
    }

    100% {
        background-position-y: 0;
        top: 0;
    }
}

.\!text-white {
    color: #fff !important;
}
